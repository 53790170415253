.footer {
  &__nl {
    // padding-top: 0.2rem;

    .button {
      margin-left: $space-unit;
    }
  }
  &.footer--clientservice {
    // margin-top: $space-unit * 6;
    .wrapper-footer {
      grid-template-columns: 100%;
      column-gap: 0;
      row-gap: 0;
    }
    .footer-item {
      padding-bottom: ($space-unit * 10);
      &:nth-child(1),
      &:nth-child(3) {
        height: initial;
      }
      &:nth-child(2) {
        grid-column-start: initial;
        grid-row-start: initial;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.newsletter-foreign-modal,
.newsletter-modal {
  &.modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }
  &--error-modal {
    &.modal {
      top: initial;
      height: auto;
      min-height: 0;
      text-align: left;
      padding: 2.2rem ($space-unit * 4);
    }
    .dialog__title {
      height: auto;
    }
    .modal__header {
      width: 100%;
      position: static;
      background: transparent;
    }
  }
}
.newsletter-module {
  margin: 8.2rem 0 9.6rem;
  &__description {
    margin-bottom: 3.2rem;
  }
}

#newsletter-foreign-modal,
#newsletter-modal {
  &.modal-isml.modal-right {
    .modal {
      &-container {
        max-width: 90vw;
        &:not(.newsletter-modal--noimg) {
          .modal-close {
            right: 1.6rem;
            top: 1.8rem;
          }
        }
      }
    }
  }
}