.footer {
  position: relative;
  z-index: 4;
  background-color: $blue;
  color: $white;

  .footer-row {
    width: 100%;
  }

  #nlFieldprivacy-label,
  #nlFieldprivacy-label-footer,
  #nlFieldMarketing-label {
    color: $grey1;
  }

  #nlFieldprivacy,
  #nlFieldprivacy-footer,
  #nlFieldMarketing {
    background-color: $blue;
    border-color: $white;

    &::after {
      @include fixedSprite('checkbox-active-w');
    }
  }

  .container {
    padding: 6rem 4rem 4rem;
  }

  &.footer--clientservice {
    margin-top: 0;
    padding: ($space-unit * 10) 0;
    border-top: 0;

    .container:nth-of-type(1) {
      padding-top: 0;
    }

    .wrapper-footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: $space-unit * 8;
      row-gap: 4rem;
    }

    .footer-item {
      &:nth-child(2) {
        grid-column-start: 1;
        grid-row-start: 2;
      }

      h3 {
        @extend %primary-xs-u;
        padding-bottom: ($space-unit * 2);
      }

      p {
        @extend %primary-s;
        margin-bottom: ($space-unit);
      }

      div {
        margin-top: ($space-unit);

        >a {
          @extend %primary-s;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
      }
    }

    .start-chat {
      @extend %primary-s;
      display: flex;
      align-items: center;

      i {
        margin-right: $space-unit;
      }
    }

    .accepted-payments {
      @include reset-list;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;

      li {
        margin-right: ($space-unit * 2);
        margin-bottom: ($space-unit * 2);
      }
    }
  }

  .newsletter-container button[type="submit"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 2rem;
    height: auto;
    width: 1.6rem;
    border: 0;
    right: 0;
    color: transparent;
    background: transparent;
  }

  a {
    color: $white;
  }

  .newsletter-popup-wrapper a {
    color: #666;
  }


  .social-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;
    padding: 0.6rem;
  }

  .footer__nl {
    padding-bottom: 0.8rem;

    &__logo {
      [class^='icon'] {
        display: none;
      }
    }

    .privacyflag {
      a {
        text-decoration: underline;
      }      
    }

  }

  .follow-us {
    margin-bottom: 2.5rem;
  }

  .footer-menu {
    @include reset-list;
  }

  .footer-menu__menuitem {
    @extend %primary-xs-u;
    text-decoration: none;
    color: $white;
    display: none;
    @include border('bottom');
    padding: 1rem 0 1rem;
    width: 100%;
    text-align: left;

    &:focus {
      @include focus-underline;
    }
  }

  .footer-countryselector {
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }

  .footer-menu__menuitem__current {
    @extend %primary-xs;
    text-transform: capitalize;
    color: $white;
    padding-left: ($space-unit * 3);
  }

  .footer-menu__section {
    @include reset-list;
    padding-bottom: ($space-unit * 10);
    text-align: left;

    li {
      &:last-child {
        .footer-menu__section__menuitem {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-menu__section__menuitem {
    display: inline-block;
    text-decoration: none;
    color: $white;
    margin-bottom: $space-unit * 4;
    @extend %primary-s;
    @include hover(0.2);

    &:focus {
      @include focus-underline;
    }

  }

  #ot-sdk-btn.ot-sdk-show-settings {
    padding: 0;
    cursor: pointer;
    font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 2.2rem;

    border: none;
    display: inline-block;
    text-decoration: none;
    color: $white;
    margin-bottom: 1.6rem;

    transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  }

  #ot-sdk-btn.ot-sdk-show-settings:hover {
    opacity: .7;
    color: $white;
    background-color: transparent;
  }

  .footer-menu--col {
    display: flex;
    flex-direction: column;
    @include border('bottom');

    .footer-menu__menuitem {
      display: flex;
      align-items: center;
      border-bottom: 0;
      @include border('top');
      height: 4.2rem;
      padding: 0;
    }

    .footer-menu__section__menuitem {
      @extend %primary-xs;
      padding: 0;
      margin-bottom: $space-unit * 3;
    }

    .footer-menu__menuitem,
    .footer-menu__section__menuitem {
      &::after {
        content: '';
        @include fixedSprite('plus');
        position: absolute;
        right: -0.8rem;
        top: 50%;

        transform: translateY(-50%);
        width: 2.4rem;
        height: 2.4rem;
        display: none;
      }
    }

    li:nth-child(1) {
      order: 3;
    }

    li:nth-child(2) {
      order: 4;
    }
  }

  .social-legals {
    display: flex;
    flex-direction: column;
    padding-bottom: ($space-unit * 6);
    align-items: flex-end;

    .social-wrapper {
      padding-bottom: ($space-unit * 16);
      display: flex;
      align-items: center;

      >a {
        width: ($space-unit * 4);
        height: ($space-unit * 4);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $space-unit * 4;
        @include hover(0.2);

        &[href*='facebook'] {
          width: ($space-unit * 3);
        }
      }
    }

    .copyright {
      @extend %primary-xxs;
      text-align: right;
    }
  }

  .socials-languages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .languageselector {
    margin-left: auto;
    display: flex;
    justify-content: center;
    color: $white;
    background-color: #071228;
    width: 8.4rem;
    border-radius: 0.4rem;
    padding: 0.2rem;

    .language {
      color: $disabled_txt_grey;
      padding: 0.6rem 0.8rem 0.6rem;
      border-radius: 0.2rem;
      width: 4rem;
      text-align: center;

      &.active {
        color: $blue;
        background-color: $white;
        padding: 0.6rem 0.8rem 0.6rem;
        border-radius: 0.2rem;

        a {
          color: $blue;
        }
      }

      a {
        color: $disabled_txt_grey;
        text-decoration: none;
        @extend %primary-xs-u;
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0 4rem 0;
    margin: 4rem 4rem 0;
    width: auto;
    height: auto;
    border-top: 0.1rem solid #ffffff30;
  }

  .accordion__group {
    text-align: left;
    position: relative;

    .accordion__item {
      &:last-child {
        @include border('bottom');

        .accordion__header {
          border-bottom: initial;
        }
      }
    }
  }

  .accordion__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: $white;
    padding: 2rem 0;
    width: 100%;
    font-size: 1.4rem;

    font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;

    &>span {
      text-align: left;
    }

    &:focus {
      outline: transparent;

      .header__open,
      .header__close {
        [data-whatintent='keyboard'] & {
          outline: $outline-width solid $outline-color;
        }
      }
    }

    &--uc {
      &>span {
        @extend %primary-xs-u;
      }
    }

    &--lc {
      &>span {
        @extend %primary-s;
      }
    }

    .header__open,
    .header__close {
      position: absolute;
      right: -0.8rem;
      top: 50%;

      transform: translateY(-50%);
      min-width: ($space-unit * 6);
      min-height: ($space-unit * 6);
      width: auto;
      height: auto;
      display: none;
      align-items: center;

      .text {
        @extend %primary-xs;
        display: none;
      }

      &::after {
        content: '';
        display: block;
        width: ($space-unit * 6);
        height: ($space-unit * 6);
      }
    }

    &--cta-text {
      &>span {
        width: calc(100% - 5.4rem);
      }

      .header__open,
      .header__close {
        .text {
          display: block;
        }

        .text,
        &::after {
          opacity: 0.55;
        }
      }
    }

    &::after {
      content: '';
      @include fixedSprite('icon-accordion-dw-w');

      transition: transform 0.3s ease-in-out;
    }

    &[aria-expanded='false'] {
      .header__open {
        display: flex;
      }

      &::after {
        transform: rotate(90deg);
      }

      &+.accordion__panel {
        max-height: 0 !important;
        border-bottom: 0.1rem solid #ffffff30;
      }
    }

    &[aria-expanded='true'] {
      &+.accordion__panel {
        height: fit-content;
      }

      &::after {
        transform: rotate(270deg);
      }

      .header__close {
        display: flex;
      }
    }

    &--payments {
      align-items: flex-start;

      .header__open,
      .header__close {
        top: 1rem;
        transform: initial;
      }

      >span {
        white-space: initial;
        line-height: 1.6;
      }
    }
  }

  .accordion__panel {
    overflow: hidden;
    visibility: visible;

    transition: all 0.3s ease-in-out;
    opacity: 0.8;
    border-bottom: 0.1rem solid $white;

    // @include animate;
    >* {
      padding-top: $space-unit;
    }

    &--grenoble {
      text-transform: uppercase;
    }
  }

}

#newsletter-success-message {
  margin-top: 0.8rem;
  color: $green_success;
}

.newsletter-container {
  position: relative;
  display: flex;
  justify-content: stretch;

  .icon--icon-arrow-dx::after {
    opacity: 0.7;
  }

  .form-group {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .invalid-feedback.globalerrors {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: -1.9rem;
  }
}

.footer #newsletter-modal  {
  #nlFieldprivacy-label,
  #nlFieldprivacy-label-footer {
    color: $dark_grey;    
  }

}

#newsletter-foreign-modal,
#newsletter-modal {
  &.modal-isml.modal-right {
    .modal {
      &-container {
        border-radius: 0.4rem;
        margin: auto;
        max-width: 44rem;  
        &.newsletter-modal--noimg {
          .newsletter-popup {
            &-wrapper {
              padding-top: 4rem;
            }
          }
        }      
      }

      &-close {
        top: 2.4rem;
        right: 2.4rem;        
      }

      &-content {
        padding: 0;  
        .footer__nl {
          padding-top: 0;
          padding-bottom: 0;         
        }      
      }
    }
  }
  
  .newsletter-popup {
    &-wrapper {
      padding: 2.4rem;
      color: $black;
    }

    &-title {
      font-size: 2rem;
      line-height: 3.2rem;
    }
  } 
}

#newsletter-foreign-modal {
  .privacyflag {
    text-align: start;
  }

  .newsletter-popup-wrapper {
    text-align: center;
    padding: 3.4rem;
  }
}

.newsletter-foreign-modal {
  .form-group {
    margin-bottom: 1.6rem;
  }

  &.modal-container {
    max-width: 44rem;
  }
}

.newsletter-foreign-modal,
.newsletter-modal {
  &.modal {
    text-align: center;
    min-width: 0;
    height: 45.5rem;
    width: 68.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 95%;
  }

  .modal__header {
    padding: 0;
    margin-bottom: ($space-unit * 4);

    h2 {
      padding-bottom: 0;
    }
  }

  &__description {
    span {
      max-width: 46rem;
      margin: 0 auto ($space-unit * 6);
      display: block;
    }
  }

  &__img-container {
    position: relative;
    width: 50%;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__columns-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
  }

  &--subscription-modal.modal {
    width: 91.6rem;
    height: auto;
    padding: 0;

    .modal {
      &__body {
        width: 100%;
        height: 100%;
      }

      &__header {
        display: none;
      }
    }

    &__description {
      width: 50%;
    }
  }

  &__title {
    margin-bottom: ($space-unit * 2);
    @extend %primary-m;
  }

  &__text-holder {
    text-align: left;
    padding: ($space-unit * 8);
    overflow: auto;
    width: 50%;
    min-height: 60rem;
  }

  &__columns-wrapper {
    .newsletter-modal__description {
      span {
        max-width: 100%;
      }
    }
  }

  &__radio-wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;

    >p {
      text-transform: uppercase;
      @extend %primary-xxs;
      margin-bottom: 1.3rem;
    }

    label {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__legal-label {
    margin-top: ($space-unit * 4);
    @extend %primary-xxs;
    color: $black_secondary;
  }
}

.newsletter-module {
  margin: 7.9rem 0 9.9rem;
  padding: 0 1.6rem;

  .footer__nl {
    padding: 0;
  }

  &__title {
    @extend %primary-l;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__description {
    @extend %primary-s;
    width: 44.6rem;
    max-width: 100%;
    margin: 0 auto 3.7rem;
    text-align: center;
  }
}

// for pages with react content, loaded gets added after a delay - set in loader.txt
// experimental - see how to handle it properly :(
#cart-show {
  .footer {
    display: none;
  }

  &.loaded {
    .footer {
      display: block;
    }
  }
}

.privacyflag.checkbox {
  display: flex;

  &.second-flag {
    pointer-events: none;
    opacity: .5;

    &.active {
      pointer-events: unset;
      opacity: 1;
    }
  }
}

footer {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}