.footer {
  .footer-menu {
    //column-count: 4;
    columns: initial;
    display: flex;

    & > li {
      width: 33%;
    }
  }

  .footer-menu--col {
    column-count: 1;
    display: block;

    & > li {
      width: initial;
    }
  }
}
