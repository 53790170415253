.footer {

  .col,
  [class^=col-] {
    padding: 0;
  }

  .container {
    padding: 6rem 2.4rem 0;
  }

  .col--footer-nl {
    order: 1;
  }

  .col--footer-menu {
    order: 3;
  }

  .socials-languages {
    order: 2;
    padding: 2.4rem 0 4.8rem;

    .social-wrapper {
      gap: 4.8rem;

      a {
        height: 1.6rem;
      }
    }
  }

  .footer-menu__section {
    margin-top: 0;
    padding-bottom: 2.4rem;
    display: grid;
    grid-gap: 0 2rem;
    grid-template-columns: auto auto;

    & a {
      line-height: 2rem;
    }
  }

  .footer__nl {
    padding-bottom: 0;
  }

  .footer-menu--col {

    .footer-menu__menuitem,
    .footer-menu__section__menuitem {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $black;
      @include border('top');
      // padding-top: 0.5rem; // TYPOGRAPHY TRICK
      min-height: 4.2rem;
      width: 100%;
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  .social-legals {
    align-items: baseline;

    .copyright {
      text-align: left;
    }
  }

  .footer-row {
    flex-direction: column;
  }

  .footer-bottom {
    display: block;
    border: 0;
    padding: 4rem 2.4rem 6rem;
    margin: 0;
  }

  .footer-policies a {
    padding-right: 1.6rem;
  }
}

.newsletter-container {
  .invalid-feedback.globalerrors {
    left: 0.4rem;
    bottom: 1.6rem;
  }
}

.newsletter-modal {
  &.modal {
    width: 48.4rem;
  }

  &__img-container {
    display: none;
  }

  &__text-holder {
    width: 100%;
    padding: 2.2rem ($space-unit * 4);
  }
}